<template>
  <div
    class="
      w-full
      md:h-[356px]
      lg:h-[385px]
      rounded-xl
      bg-white
      shadow-modal
      animate-pulse
    "
  >
    <span
      class="bg-wrh-gray-extralight w-full h-[224px] block rounded-t-xl"
    ></span>
    <div class="p-5 xl:p-5 flex flex-col">
      <span class="w-1/3 h-5 bg-wrh-gray-extralight mb-2"></span>
      <span class="w-1/2 h-3 bg-wrh-gray-extralight mt-2"></span>
      <span class="w-3/4 h-3 bg-wrh-gray-extralight mt-2"></span>
      <span class="w-1/2 h-3 bg-wrh-gray-extralight mt-2"></span>
      <span class="w-3/4 h-3 bg-wrh-gray-extralight mt-2"></span>
    </div>
  </div>
</template>
